import React, { lazy, Suspense, useReducer } from 'react';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import { LightTheme, BaseProvider } from 'baseui';

import ActivityLoader from './components/ActivityLoader';
import { NavBarContextProvider } from './contexts/navBarContext';

const RootDOM = lazy(() => import('./RootDOM'));

const engine = new Styletron();

function App() {
  const [navBarState, navBarStateDispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'SWITCH_SIDE_NAV':
          return {
            ...state,
            sideNavVisibility: !state.sideNavVisibility
          };
        case 'SET_TOP_NAV_CONTENT':
          return {
            ...state,
            topNavMiddleContent: action.payload
          };
        default:
          return state;
      }
    },
    {
      sideNavVisibility: false,
      scrollingNavContent: '',
      topNavMiddleContent: ''
    }
  );

  const toggleSideNav = () => {
    navBarStateDispatch({ type: 'SWITCH_SIDE_NAV' });
  };

  const setTopNavMiddleContent = content => {
    navBarStateDispatch({ type: 'SET_TOP_NAV_CONTENT', payload: content });
  };

  return (
    <NavBarContextProvider
      value={{ navBarState, toggleSideNav, setTopNavMiddleContent }}
    >
      <StyletronProvider value={engine}>
        <BaseProvider theme={LightTheme}>
          <Suspense fallback={<ActivityLoader />}>
            <RootDOM sideNavVisibility={navBarState.sideNavVisibility} />
          </Suspense>
        </BaseProvider>
      </StyletronProvider>
    </NavBarContextProvider>
  );
}

export default App;
