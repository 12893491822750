import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const ActivityLoader = () => {
  return (
    <ActivityLoaderContainer
      animate={{ backgroundPositionX: '100%' }}
      transition={{
        yoyo: Infinity,
        duration: 0.5,
        ease: 'easeInOut'
      }}
    />
  );
};

const ActivityLoaderContainer = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-image: linear-gradient(
    to right,
    #b20007 0%,
    #d2464b 36%,
    #f15a22 73%,
    #f8a88a 100%
  );
  background-size: 200%;
  background-repeat: repeat;
`;

export default ActivityLoader;
